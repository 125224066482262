import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem('token')  || null,
    user : {},

  },
  getters: {
    loggedIn(state){
      return state.token !== null
    }
  },
  mutations: {
    removeToken(state){
      return state.token = null
    },
    setUser(state , user){
      state.user = user;
    },
  },
  actions: {
    removeToken(context){
      context.commit('removeToken')
    }
  },
 
})

export default store


