import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    component: () => import('../views/website/index.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/website/HomeView.vue')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/website/AboutView.vue')
      },
    ]
  },
  {
    path: '/',
    component: () => import('../views/staff/index.vue'),
    children: [
      {
        path: '/staff/dashboard',
        name: 'staff-dashboard',
        component: () => import('../views/staff/dashboard.vue'),
        meta: {
          requiresAuth: true
        }
      },
    
      {
        path: '/staff/members',
        name: 'members',
        component: () => import('../views/staff/member/manage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff/members/taekwondo',
        name: 'membersTaekwondo',
        component: () => import('../views/staff/member/taekwondo.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff/members/aikido',
        name: 'membersAikido',
        component: () => import('../views/staff/member/aikido.vue'),
        meta: {
          requiresAuth: true
        }
      },      {
        path: '/staff/members/full-contact',
        name: 'membersFullContact',
        component: () => import('../views/staff/member/fullConatct.vue'),
        meta: {
          requiresAuth: true
        }
      },      {
        path: '/staff/members/karate',
        name: 'membersKarate',
        component: () => import('../views/staff/member/karate.vue'),
        meta: {
          requiresAuth: true
        }
      },      {
        path: '/staff/members/boxe',
        name: 'membersboxe',
        component: () => import('../views/staff/member/boxe.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff/members/create',
        name: 'members-create',
        component: () => import('../views/staff/member/create.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff/members/:id/edit',
        name: 'members-edit',
        component: () => import('../views/staff/member/edit.vue'),
        meta: {
          requiresAuth: true
        }
      },
      
      {
        path: '/staff/members/:id/details',
        name: 'member-details',
        component: () => import('../views/staff/member/details.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff/off/members',
        name: 'off-members',
        component: () => import('../views/staff/memberOff.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff/active/members',
        name: 'active-members',
        component: () => import('../views/staff/memberActive.vue'),
        meta: {
          requiresAuth: true
        }
      },
      

      {
        path: '/staff/update/password',
        name: 'update-password',
        component: () => import('../views/staff/updatePs.vue'),
        meta: {
          requiresAuth: true
        }
      },
      ////////////////////////////
      {
        path: '/staff/invoice',
        name: 'invoice',
        component: () => import('../views/staff/invoice/manage.vue'),
        meta: {
          requiresAuth: true
        }
      },
      
      {
        path: '/staff/invoice/create',
        name: 'invoice-create',
        component: () => import('../views/staff/invoice/create.vue'),
        meta: {
          requiresAuth: true
        }
      },
        
      {
        path: '/staff/invoice/:member/create',
        name: 'member-invoice-create',
        component: () => import('../views/staff/invoice/invoiceCreate.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff/invoice/:id/edit',
        name: 'invoice-edit',
        component: () => import('../views/staff/invoice/edit.vue'),
        meta: {
          requiresAuth: true
        }
      },
           ////////////////////////////
           {
            path: '/staff/expense',
            name: 'expense',
            component: () => import('../views/staff/expense/manage.vue'),
            meta: {
              requiresAuth: true
            }
          },
          
          {
            path: '/staff/expense/create',
            name: 'expense-create',
            component: () => import('../views/staff/expense/create.vue'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: '/staff/expense/:id/edit',
            name: 'expense-edit',
            component: () => import('../views/staff/expense/edit.vue'),
            meta: {
              requiresAuth: true
            }
          },
    ]
  },
  {
    path: '/staff/login',
    name: 'staff-login',
    component: () => import('../views/staff/login.vue'),
    meta: {
      visited: true
    }
  },
  {
    path: '/staff/register',
    name: 'staff-register',
    component: () => import('../views/staff/register.vue'),
    meta: {
      visited: true
    }
  },
 
  

  //dashboard   
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active'
})


router.beforeEach((to, from, next) => {
  
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters.loggedIn) {
      next({name : 'staff-login'})
    }else{
      next()
    }
  }else if(to.matched.some(record => record.meta.visited)) {
    if(store.getters.loggedIn) {
      next({name : 'staff-dashboard'})
    }else{
      next()
    }
  }else{
    next()
  }

})

export default router



//http://mylaravelvue.local/api/v1/auth/login


//http://127.0.0.1:8000/api/v1/auth/login

