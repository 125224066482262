import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"

import JsonExcel from "vue-json-excel";

import moment from 'moment';

import { driver } from "driver.js";
import "driver.js/dist/driver.css";



Vue.component("downloadExcel", JsonExcel);

//import Swal from 'sweetalert2'

//Vue.component('pagination', require('laravel-vue-pagination'));

const options = {

}

Vue.filter("date", function (date, format = "YYYY-MM-DD") {
  return date ? moment(date).format(format) : "__";
});

Vue.use(Toast, options);

Vue.component("driver", driver);


import 'bootstrap'

import 'admin-lte/dist/css/adminlte.css'; // Import AdminLTE CSS
import 'admin-lte/dist/js/adminlte'; // Import AdminLTE JavaScript


import '../src/assets/scss/app.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

